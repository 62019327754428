const data = [
    "path815",
    "Path_196",
    "Path_5",
    "Path_19",
    "Path_20",
    "Path_21",
    "Rectangle_71",
    "Subtraction_1",
    "Subtraction_3",
    "Path_118",
    "Path_122",
    "Path_124",
    "Ellipse_9",
    "Ellipse_10",
    "Rectangle_84",
    "Rectangle_85",
    "Path_47",
    "Path_48",
    "Path_49",
    "Path_51",
    "Path_52",
    "Path_53",
    "Path_55",
    "Path_56",
    "Path_57",
    "Path_64",
    "Ellipse_13",
    "Path_158",
    "Path_159",
    "Path_161",
    "Ellipse_14",
    "Ellipse_15",
    "Ellipse_16",
    "Ellipse_17",
    "Path_128",
    "Rectangle_88",
    "Rectangle_89",
    "Rectangle_90",
    "Rectangle_90",
    "Rectangle_91",
    "Rectangle_92",
    "Rectangle_93",
    "Rectangle_94",
    "Ellipse_25",
    "Rectangle_105",
    "Path_211",
    "Path_224",
    "Path_225",
    "Path_226",
    "Ellipse_30",
];
export default data;